import styled from 'styled-components'

export const HomeWrapper = styled.div`
  background: ${({ theme }) => theme.colors.homeBackground};
  background-size: cover;
  padding: 80px 0 0;
  font-weight: 400;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px 0 0;
  }

  button {
    font-size: 16px;
    font-weight: 600;
    padding: 0 45px;
    height: 65px;
    border-radius: 48px;
  }

  .mobile-center {
    ${({ theme }) => theme.mediaQueries.mobile} {
      text-align: center;
    }
  }

  .line {
    width: 189px;
    height: 5px;
    margin: 45px 0;
    display: inline-block;
    background: ${({ theme }) => theme.colors.secondary};

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin: 15px 0;
    }
  }

  .title {
    font-size: 44px;
    font-weight: 600;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.homeTitleColor};

    ${({ theme }) => theme.mediaQueries.mobile} {
      font-size: 30px;
    }
  }

  .title-color {
    color: ${({ theme }) => theme.colors.homeTitleColor};
  }

  .desc {
    color: ${({ theme }) => theme.colors.homeDescColor};
  }

  .white {
    color: #fff;
  }

  .stop {
    display: flex;
    align-items: center;
    margin-bottom: 150px;
    position: relative;
    padding-top: 100px;

    .title {
      font-size: 54px;
    }

    .simpler {
      font-size: 32px;
      margin-bottom: 47px;
      font-weight: 400;
      color: ${({ theme }) => theme.isDark ? '#fff' : '#000'};
    }

    .cross-img {
      position: absolute;
      width: 925px;
      top: 0;
      right: 0;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      display: block;
      margin-bottom: 20px;
      padding-top: 50px;

      .title {
        font-size: 30px;
      }

      .simpler {
        margin: 14px 0 15px;
        font-size: 14px;
      }

      button {
        font-size: 12px;
        padding: 5px 9px;
        height: auto;
      }

      .cross-img {
        width: 284px;
        height: auto;
        margin: 40px auto;
        position: static;
      }
    }
  }

  .blockchain-wrapper {
    background-image: ${({ theme }) => `url(/images/home/home/blockchain-bg${theme.isDark ? '' : '-light'}.png)`};
    background-size: 100% 100%;
    background-position: top center;
    font-size: 18px;
    margin-bottom: 170px;
    text-align: center;
    padding: 100px 0;

    .blockchain {
      line-height: 23px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-top: 10px;
      margin-bottom: 50px;
      font-size: 14px;
      background-size: inherit;
    }
  }

  .swap-app {
    margin-bottom: 70px;
    padding: 40px 30px;
    display: flex;
    justify-content: space-between;

    .left-layout {
      text-align: right;
    }

    .rounded-img-r1 {
      width: 707px;
    }

    .rounded-img-r2 {
      width: 564px;
    }

    .rounded-img-r3 {
      width: 621px;
    }

    h3 {
      font-size: 44px;
      font-weight: 600;
    }

    h4 {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 60px;
      max-width: 630px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-top: 18px;
      padding: 20px 15px;
      display: block;

      h3 {
        font-size: 22px;
        margin-bottom: 16px;
      }

      .rounded-img-r1 {
        width: 160px;
        height: auto;
        margin: 0;
      }

      .rounded-img-r2 {
        width: 160px;
        height: auto;
        margin: 0;
      }

      .rounded-img-r3 {
        width: 160px;
        height: auto;
        margin: 0;
      }

      p {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 12px;
      }

      button {
        padding: 0 20px;
        height: 35px;
      }
    }
  }

  .featured {
    overflow: hidden;
    background: ${({ theme }) => (theme.isDark ? '#131519' : '#F2F5F9')};
    margin-bottom: 132px;

    .main {
      padding: 60px 30px;

      h3 {
        font-size: 34px;
        margin-bottom: 56px;
        font-weight: 600;
        text-align: center;
      }

      ${({ theme }) => theme.mediaQueries.mobile} {
        padding: 40px 20px;

        h3 {
          font-size: 17px;
          text-align: center;
          margin-bottom: 40px;
        }
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 50px;
    }
  }

  .news-wrapper {
    margin-top: 44px;
    display: flex;
    margin-bottom: 190px;

    .border-box {
      padding: 20px;
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: 21px;
      background: ${({ theme }) => theme.colors.homeBorderBoxBg};
      border: ${({ theme }) => theme.colors.homeBorderBoxBd};
      border-radius: 21px;
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .tw-img {
        width: 167px;
        height: 167px;
        background: ${({ theme }) => theme.colors.background};
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 47px;

        img {
          width: 87px;
        }
      }

      h3 {
        font-size: 32px;
        font-weight: 400;
      }

      p {
        color: #A9A9A9;
        font-size: 22px;
        margin: 15px 0;
      }

      button {
        padding: 0 30px;
        height: 45px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 0;
      display: block;

      .border-box {
        width: 100%;
        margin-bottom: 20px;

        .tw-img {
          width: 110px;
          height: 110px;
          margin-right: 20px;
        }

        h3 {
          margin: 12px 0 16px;
          font-size: 14px;
        }

        p {
          color: #8e5fac;
          font-size: 12px;
          margin-bottom: 20px;
        }

        button {
          width: 100px;
          font-size: 14px;
          padding: 0;
          height: 35px;
          line-height: 35px;
        }
      }
    }
  }

  .largest-box {
    .title {
      position: absolute;
    }

    button {
      padding: 0 70px;
      margin-top: 30px;
    }

    &:nth-of-type(2) {
      margin: 140px 0;
    }

    .largest-img-1 {
      width: 620px;
    }

    .largest-img-2 {
      width: 537px;
    }

    .largest {
      color: ${({ theme }) => theme.colors.homeLargestItemColor};
      font-size: 18px;

      .middle-largest {
        margin: 23px 0;
      }

      h4 {
        font-size: 18px;
        max-width: 500px;
        line-height: 1.5;
        font-weight: 400;
        margin-bottom: 45px;
      }

      .largest-icon {
        width: 39px;
        height: 39px;
        background: ${({ theme }) => (theme.isDark ? '#131519' : '#E0EFFD')};
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 17px;
      }
    }

    .left-layout {
      display: flex;
      flex-direction: column;
      align-items: end;
      text-align: right;

      h4 {
        text-align: left;
      }

      .flex {
        flex-direction: row-reverse;
      }

      .largest-icon {
        margin-right: 0;
        margin-left: 17px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      &:nth-of-type(2) {
        margin: 20px 0;
      }

      button {
        padding: 0 20px;
        margin-top: 30px;
        height: 45px;
      }

      .title {
        font-size: 22px;
      }

      .largest-img-1 {
        width: 300px;
        margin: auto;
      }

      .largest-img-2 {
        width: 300px;
        margin: auto;
      }

      .largest {
        display: block !important;
        font-size: 12px;
        margin-bottom: 50px;

        .middle-largest {
          margin: 10px 0;
        }

        h4 {
          font-size: 12px;
          max-width: inherit;
          margin-top: 15px;
          margin-bottom: 15px;
          line-height: 1.5;
        }

        .largest-icon {
          width: 25px;
          height: 25px;
          border-radius: 7px;
          margin-right: 10px;

          img {
            width: 50%;
          }
        }
      }
    }
  }

  .best-rates {
    background: ${({ theme }) => (theme.isDark ? 'linear-gradient(180deg, #131519 0%, rgba(19, 21, 25, 0.00) 86%)' : 'linear-gradient(180deg, #F2F5F9 0%, rgba(250, 251, 255, 0.00) 85.99%, rgba(255, 255, 255, 0.00) 86%)')};
    text-align: center;
    padding-top: 100px;

    button {
      padding: 0 70px;
    }

    p {
      font-size: 18px;
      margin: 40px auto;
      max-width: 970px;
      line-height: 1.5;
    }

    .best-rates-li {
      display: grid;
      grid-template-columns:repeat(3, minmax(0, 1fr));
      gap: 8px;
      margin-top: 100px;

      .best-rates-li-item {
        border-radius: 21px;
        background: ${({ theme }) => (theme.isDark ? '#131519' : '#F2F5F9')};
        position: relative;
        height: 166px;
        font-size: 18px;
        padding: 83px 100px 0;
        line-height: 1.3;

        img {
          position: absolute;
          top: -45px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding-top: 50px;

      button {
        padding: 0 20px;
        height: 45px;
      }

      p {
        font-size: 16px;
        margin: 20px auto;
      }

      .best-rates-li {
        display: grid;
        grid-template-columns:repeat(1, minmax(0, 1fr));
        gap: 8px;
        margin-top: 50px;

        .best-rates-li-item {
          height: auto;
          font-size: 16px;
          padding: 20px 50px;

          img {
            position: static;
            transform: none;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .fees-wrapper {
    background: ${({ theme }) => (theme.isDark ? 'linear-gradient(0deg, #182B3A 0%, #182B3A 100%), rgba(91, 185, 252, 0.20)' : '#F2F5F9')};
    margin-top: 127px;
    margin-bottom: 113px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }

  .fees {
    padding-top: 88px;
    position: relative;
    height: 468px;

    h2 {
      font-size: 34px;
      font-weight: 600;
    }

    p {
      max-width: 700px;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 30px;
      line-height: 1.3;
    }

    .fees-img {
      position: absolute;
      top: 37px;
      right: 0;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin: 20px 20px 50px;
      padding: 20px 0;
      height: auto;

      h2 {
        font-size: 22px;
        max-width: 200px;
        line-height: 1.2;
      }

      p {
        font-size: 12px;
        max-width: 200px;
        margin-bottom: 20px;
      }

      .fees-img {
        bottom: inherit;
        top: 0;
        right: 10px;
        width: 140px;
      }

      button {
        height: 23px;
        font-size: 14px;
      }
    }
  }

  .users {
    margin-top: 50px;
    margin-bottom: 200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 39px;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background-color: #F5F5F5;
    }

    .users-item {
      background: linear-gradient(90deg, #8e5fac 0%, #8d33c5 100%);
      border-radius: 14px;
      padding: 25px;
      font-weight: 400;
      flex-shrink: 0;
      font-size: 18px;

      div {
        margin-bottom: 50px;
        line-height: 1.4;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin: 17px -20px 50px;
      padding: 0 20px 10px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 0;

      .users-item {
        width: 237px;
        font-size: 13px;
        border-radius: 7px;
        padding: 10px 10px 20px;
        margin-right: 20px;

        div {
          margin-bottom: 20px;
          line-height: 1.2;
        }
      }
    }
  }

  .app {
    margin-bottom: 157px;
    text-align: center;

    p.desc {
      font-size: 18px;
      margin-bottom: 50px;
    }

    .app-item {
      width: 50%;
      height: 144px;
      border-radius: 21px;
      background: ${({ theme }) => (theme.isDark ? '#131519' : '#F2F5F9')};
      margin-right: 12px;
      position: relative;
      overflow: hidden;

      .app-item-img-1, .app-item-img-2 {
        width: 168px;
        display: flex;
        align-items: center;
        padding-left: 40px;
      }

      .app-item-img-1 {
        img {
          width: 67px;
        }
      }

      .app-item-img-2 {
        img {
          width: 74px;
        }
      }

      p {
        font-size: 18px;
        margin-bottom: 16px;
        text-align: left;
        padding-top: 30px;
        font-weight: 600;
      }

      button {
        height: 45px;
        margin-right: 14px;
        padding: 0 20px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 80px;

      p.desc {
        font-size: 12px;
        margin: 12px 0 16px;
      }

      .app-item {
        height: auto;
        text-align: center;
        margin-right: 12px;
        display: block !important;

        &:last-of-type {
          margin-right: 0;
        }

        .app-item-img {
          display: none;
        }

        p {
          font-size: 15px;
          margin: 20px 0;
          text-align: center;
          padding-top: 0;
        }

        .button-img {
          width: 12px;
          left: 10px;
        }

        button {
          width: 130px;
          height: 23px;
          font-size: 12px;
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }

  .chain {
    margin-top: 60px;
    margin-bottom: 164px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 20px;
    
    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-top: 30px;
      margin-bottom: 50px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 10px;
    }
  }

  .online-token {
    text-align: center;
    margin-bottom: 141px;

    p {
      margin: 30px 0 50px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 50px;

      p {
        margin: 15px 0 20px;
        font-size: 12px;
      }
    }
  }

  .trusted {
    text-align: center;
    background: ${({ theme }) => (theme.isDark ? '#131519' : '#F2F5F9')};
    padding: 90px 0;

    .title {
      margin-bottom: 52px;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 30px 0;

      .title {
        margin-bottom: 10px;
      }
    }
  }

  .security {
    padding: 110px 0 80px;
    text-align: center;

    .title {
      margin-bottom: 52px;
    }

    .flex {
      padding: 0 40px;
      background: ${({ theme }) => theme.colors.homeSecurityBg};

      img:nth-of-type(1) {
        height: 58px;
      }

      img:nth-of-type(2) {
        height: 160px;
      }

      img:nth-of-type(3) {
        height: 38px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 20px;

      .title {
        margin-bottom: 10px;
      }

      .flex {
        padding: 20px;
        justify-content: center !important;

        img:nth-of-type(1) {
          height: 25px;
          margin-right: 56px;
        }

        img:nth-of-type(2) {
          height: 50px;
        }

        img:nth-of-type(3) {
          height: 16px;
        }
      }
    }
  }

  .community {
    background: #131519;
    padding: 80px 0;

    .title {
      margin-bottom: 40px;
    }

    .border-box {
      padding: 20px;
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: 21px;
      background: #07080A;
      border: 1px solid #131519;
      border-radius: 21px;
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .tw-img {
        width: 122px;
        height: 122px;
        background: #131519;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 27px;
        flex-shrink: 0;

        img {
          width: 87px;
        }
      }

      h3 {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 30px;
        color: #fff;
      }

      button {
        width: 118px;
        padding: 0 20px;
        height: 45px;
        margin-right: 14px;
      }
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
      padding: 20px 18px;

      .flex {
        display: block;
      }
      
      .border-box {
        width: 100%;
        margin-bottom: 30px;

        h3 {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 600;
        }

        button {
          width: 118px;
          padding: 0 20px;
          height: 25px;
          font-size: 14px;
          margin-right:0;
        }
      }
    }
  }
`
