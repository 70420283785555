import React from 'react'
import { useTranslation } from '@pancakeswap/localization'
import { Button } from '@pancakeswap/uikit'
import { useRouter } from 'next/router'
import { HomeWrapper } from './style'
import { useTheme } from 'next-themes'

const Home: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const { resolvedTheme } = useTheme()
  const isDark = resolvedTheme === 'dark'

  const imgSu = isDark ? '' : '-light'

  return (
    <HomeWrapper>
      <div className='main stop'>
        <div style={{ zIndex: 3 }}>
          <div className='title'>
            {t('One Stop Cross-chain')}
          </div>
          <div className='line'></div>
          <div className='simpler desc'>{t('Simpler · faster · Safer')}</div>
          <div>
            <Button
              style={{ marginBottom: 20 }}
              className='custom'
              onClick={() => router.push('swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=BNB')}
            >
              {t('LAUNCH APP')}
            </Button>
          </div>
          <div>
            <Button className='ghost'>{t('DOWNLOAD MOBILE APP')}</Button>
          </div>
        </div>

        <div className='cross-img'>
          <img src={`/images/home/home/home${imgSu}.png`} alt='' />
        </div>
      </div>

      <div className=' blockchain-wrapper'>
        <div className='main title'>{t('About TUP Blockchain')}</div>

        <div className='main'>
          <div className='line'></div>
        </div>

        <div className='main blockchain desc'>
          <p>
            {t('TUP Blockchain is a platform that allows users to trade, invest, and transact in cryptocurrencies.')}
            {t('Established in 2017')}
            {t(', the company has the backing of renowned investors')}
            {t('Draper Dragon and Node Capital.')}
            {t('With over')} {t('800+ cryptocurrencies')}
            {t('available on the platform and access to')}
            {t('50+ public blockchain networks,')}
            {t('TUP Blockchain offers a comprehensive suite of services for crypto users, TUP Blockchain is a trusted partner for anyone looking to engage with the world of cryptocurrencies.')}
          </p>
        </div>
      </div>

      <div className='main'>
        <div className='swap-app'>
          <div className='rounded-img-r1 pc-show'>
            <img src={`/images/home/home/App${imgSu}.png`} alt='' />
          </div>

          <div className='left-layout'>
            <h3 className='title-color'>{t('Our Products')}</h3>

            <div className='line'></div>

            <h4 className='title-color'>{t('TUP APP')}</h4>

            <p
              className='desc'>{t('After registration, you can use one-click cross-chain swap in the wallet, as well as multiple additional functions including social media red packets, payment, aggregate transactions,group buying, wealth management, mining pools, etc.')}</p>

            <div className='flex justify-between items-center'>
              <div className='rounded-img-r1 mobile-show'>
                <img src={`/images/home/home/DOTApp${imgSu}.png`} alt='' />
              </div>

              <Button style={{ marginLeft: 'auto' }} className='custom'>{t('Download')}</Button>
            </div>
          </div>
        </div>

        <div className='swap-app'>
          <div>
            <h3 className='title-color'>{t('TUP Allchain Bridge')}</h3>

            <div className='line'></div>

            <p
              className='desc'>{t('H5 version one-stop cross-chain swap: lt can be directly connected to the user wallet, registration or login required. The swap can be accomplished simply with one-click')}</p>

            <div className='flex justify-between items-center'>
              <Button className='custom'>{t('Launch App')}</Button>

              <div className='rounded-img-r2 mobile-show'>
                <img src={`/images/home/home/bridge${imgSu}.png`} alt='' />
              </div>
            </div>
          </div>

          <div className='rounded-img-r2 pc-show'>
            <img src={`/images/home/home/bridge${imgSu}.png`} alt='' />
          </div>
        </div>

        <div className='swap-app'>
          <div className='rounded-img-r3 pc-show'>
            <img src={`/images/home/home/bridgers${imgSu}.png`} alt='' />
          </div>

          <div className='left-layout'>
            <h3 className='title-color'>{t('TUP Bridgers')}</h3>

            <div className='line'></div>

            <p
              className='desc'>{t('A decentralized trading platform currently deployed on multiple public chains (currentlysupporting BSC,Heco, OEC,five EVM chains).It has the characteristics of security.ow transactioncan enjoy ultra-low fees and fasterspeed to completechain or dua-chai')}</p>

            <div className='flex justify-between items-center'>
              <div className='rounded-img-r3 mobile-show'>
                <img src={`/images/home/home/bridgers${imgSu}.png`} alt='' />
              </div>

              <Button style={{ marginLeft: 'auto' }} className='custom'>{t('Launch App')}</Button>
            </div>
          </div>
        </div>
      </div>

      <div className='featured'>
        <div className='main'>
          <h3 className='title-color'>{t('TUP BLOCKCHAIN HAS BEEN FEATURED ON')}</h3>

          <div><img src={`/images/home/home/featired${imgSu}.png`} alt='' /></div>
        </div>
      </div>

      <div className='main news'>
        <div className='title'>
          {t('Latest News')}
        </div>

        <div className='news-wrapper'>
          <div className='border-box'>
            <div className='tw-img'>
              <img src='/images/home/home/tw.png' alt='' />
            </div>

            <div>
              <h3>{t('EN Twitter')}</h3>
              <p>{t('Tweets by TUP')}</p>
              <Button className='custom'>{t('FOLLOW')}</Button>
            </div>
          </div>

          <div className='border-box'>
            <div className='tw-img'>
              <img src='/images/home/home/tw.png' alt='' />
            </div>

            <div>
              <h3>{t('CN Twitter')}</h3>
              <p>{t('Tweets by TUP')}</p>
              <Button className='custom'>{t('FOLLOW')}</Button>
            </div>
          </div>
        </div>
      </div>

      <div className='main'>
        <div className='largest-box'>
          <LargestItem
            title='TUP Blockchain products support over 300 cryptocurrencies across Ethereum, BSC, HECO, Polygon, Polkadot, OKExChain and TRON.'
            imgUrl={`/images/home/home/largest-1${imgSu}.png`}
            label1Text='Over 35,000 direct trading cross-chain pairs'
            label1Icon='/images/home/home/largest-icon-1.png'
            label2Text='Fastest growing selection of cryptocurrencies'
            label2Icon='/images/home/home/largest-icon-2.png'
            label3Text='Multiple blockchains and layer 2 networks'
            label3Icon='/images/home/home/largest-icon-3.png'
            imgClassName='largest-img-1'
            header={<>
              <div className='title'>
                {t('Largest selection of currencies')}
              </div>
              <div style={{ height: 60 }}></div>
              <div className='line'></div>
            </>}
            isLeft={true}
          />
        </div>

        <div className='largest-box'>
          <LargestItem
            title='TUP Blockchain products are built on over four years of cross-chain development experience holding security at its core.'
            imgUrl={`/images/home/home/largest-2${imgSu}.png`}
            label1Text='Multi-layer security for all transactions'
            label1Icon='/images/home/home/largest-icon-4.png'
            label2Text='Trusted by over 100 API wallet API partners'
            label2Icon='/images/home/home/largest-icon-5.png'
            label3Text='24/7 Dedicated support'
            label3Icon='/images/home/home/largest-icon-6.png'
            imgClassName='largest-img-2'
            header={<>
              <div className='title'>
                {t('Built on a robust and powerful platform')}
              </div>
              <div style={{ height: 60 }}></div>
              <div className='line'></div>
            </>}
            isLeft={false}
          />
        </div>
      </div>

      <div className='best-rates'>
        <div className='main'>
          <div className='title'>
            {t('Best rates')}
          </div>

          <p
            className='desc'>{t('TUP Blockchain products are programmed to find and select the best rate across diverse set of exchanges, DEX, CEX, bridges, and more!')}</p>

          <Button className='custom'>{t('Download')}</Button>

          <div className='best-rates-li'>
            <div className='best-rates-li-item'>
              <img src='/images/home/home/largest-icon-7.png' alt='' />
              <div>{t('Connected to over 30 CEX and DEX')}</div>
            </div>

            <div className='best-rates-li-item'>
              <img src='/images/home/home/largest-icon-8.png' alt='' />
              <div>{t('Discover the most efficient trading route')}</div>
            </div>

            <div className='best-rates-li-item'>
              <img src='/images/home/home/largest-icon-9.png' alt='' />
              <div>{t('Trade safely with fees as low as 0.1%*')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='fees-wrapper'>
        <div className='main fees'>
          <h2>{t('Even lower fees for loyal supporters')}</h2>

          <div className='line'></div>

          <p
            className='desc'>{t('All wallets that hold 500,000 TUP or more will receive 50% off fees on all transaction on TUP Swap, dropping fees to only 0.1%.')}</p>
          <div>
            <Button className='custom' style={{ marginRight: 16 }}>{t('DETAILS')}</Button>
            <Button className='black'>{t('SWAP NOW')}</Button>
          </div>

          <img src={`/images/home/home/fees${imgSu}.png`} className='fees-img' alt='' />
        </div>
      </div>

      <div className='main app'>
        <div className='title'>
          {t('Unlock more with TUP Mobile App')}
        </div>

        <div className='line'></div>

        <p
          className='desc'>{t('Enjoy numerous unique features such as secure wallet, cross-chain swap, send & receive, red packets, and more!')}</p>

        <div className='flex white'>
          <div className='app-item flex'>
            <div className='app-item-img-1'>
              <img src={`/images/home/home/appStore${imgSu}.png`} className='app-item-img' alt='' />
            </div>

            <div>
              <p className='title-color'>{t('Download for iOS')}</p>

              <div>
                <Button className='custom'>
                  {t('APP STORE')}
                </Button>

                <Button className='custom'>
                  {t('TESTFLIGHT')}
                </Button>
              </div>
            </div>
          </div>

          <div className='app-item flex'>
            <div className='app-item-img-2'>
              <img src={`/images/home/home/apk${imgSu}.png`} className='app-item-img' alt='' />
            </div>

            <div>
              <p className='title-color'>{t('Download for Android')}</p>

              <div>
                <Button className='custom'>
                  {t('PLAY STORE')}
                </Button>

                <Button className='custom'>
                  {t('APK')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='main'>
        <div className='title mobile-center'>
          {t('Chain Supported')}
        </div>

        <div className='chain'>
          {Array.from(Array(21).keys()).map(item => (
            <div key={item}>
              <img src={`/images/home/chain/${184 + item}.png`} alt='' />
            </div>
          ))}
        </div>
      </div>

      <div className='main online-token'>
        <div className='title mobile-center'>
          {t('Online Token')}
        </div>

        <p
          className='desc'>{t('Enjoy numerous unique features such as secure wallet, cross-chain swap, send & receive, red packets, and more!')}</p>

        <img src='/images/home/home/Cryptocurrency.png' alt='' />
      </div>

      <div className='trusted'>
        <div className='title mobile-center'>
          {t('Trusted Partners')}
        </div>

        <div className='main'>
          <img src={`/images/home/home/partners${imgSu}.png`} alt='' />
        </div>
      </div>

      <div className='main security'>
        <div className='title mobile-center'>
          {t('security related partner')}
        </div>

        <div className='flex justify-between items-center flex-wrap'>
          <img src='/images/home/home/partner-1.png' alt='' />
          <img src='/images/home/home/partner-2.png' alt='' />
          <img src='/images/home/home/partner-3.png' alt='' />
        </div>
      </div>

      <div className='community'>
        <div className='main'>
          <div className='title white'>
            {t('Community')}
          </div>

          <div className='flex'>
            <div className='border-box'>
              <div className='tw-img'>
                <img src='/images/home/home/tw.png' alt='' />
              </div>

              <div>
                <h3>{t('Twitter')}</h3>
                <div className='pc-show'>
                  <Button className='custom'>{t('EN')}</Button>
                  <Button className='custom'>{t('CN')}</Button>
                </div>

                <div className='mobile-show'>
                  <Button className='custom' marginBottom='10px'>{t('EN')}</Button>
                  <Button className='custom'>{t('CN')}</Button>
                </div>
              </div>
            </div>

            <div className='border-box'>
              <div className='tw-img'>
                <img src='/images/home/home/telegram.png' alt='' />
              </div>

              <div>
                <h3>{t('Telegram')}</h3>

                <div className='pc-show'>
                  <Button className='custom'>{t('EN')}</Button>
                  <Button className='custom'>{t('CN')}</Button>
                  <Button className='custom'>{t('NEWS')}</Button>
                </div>

                <div className='mobile-show'>
                  <Button className='custom' marginBottom='10px'>{t('EN')}</Button>
                  <Button className='custom' marginBottom='10px'>{t('CN')}</Button>
                  <Button className='custom'>{t('NEWS')}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HomeWrapper>
  )
}

const LargestItem = ({
                       header,
                       title,
                       imgUrl,
                       imgClassName,
                       label1Text,
                       label1Icon,
                       label2Text,
                       label2Icon,
                       label3Text,
                       label3Icon,
                       isLeft,
                     }) => {
  const { t } = useTranslation()

  return (
    <div className='largest flex justify-between items-center'>

      {isLeft && <div className={imgClassName}>
        <img src={imgUrl} alt='' />
      </div>}

      <div className={isLeft ? 'left-layout' : ''}>
        {header}

        <h4 className='desc'>{t(title)}</h4>

        <div className='flex items-center'>
          <div className='largest-icon'>
            <img src={label1Icon} alt='' />
          </div>

          <div>{t(label1Text)}</div>
        </div>

        <div className='flex items-center middle-largest'>
          <div className='largest-icon'>
            <img src={label2Icon} alt='' />
          </div>

          <div>{t(label2Text)}</div>
        </div>

        <div className='flex items-center'>
          <div className='largest-icon'>
            <img src={label3Icon} alt='' />
          </div>

          <div>{t(label3Text)}</div>
        </div>

        <Button className='custom'>{t('Download')}</Button>
      </div>

      {!isLeft && <div className={imgClassName}>
        <img src={imgUrl} alt='' />
      </div>}
    </div>
  )
}

export default Home
